import tw, { styled } from 'twin.macro';

export const Container = tw.div`
  fixed top-0 left-0 w-full h-full bg-black bg-opacity-92 z-30 
`;

export const CloseBtn = tw.button`
  absolute text-5xl right-5vw md:right-12 top-0 text-white z-40 hover:text-gray-500
`;

export const Glide = tw.div`
  h-full flex items-center justify-center
`;

export const Slides = tw.ul`
  justify-center items-center
`;

export const Slide = tw.li`
  flex justify-center
`;

export const SlideImg = tw.img`
  max-h-gallery max-w-gallery md:max-h-gallery-md md:max-w-gallery-md self-start
`;

export const Arrows = tw.div`
  absolute hidden md:block
`;

export const Arrow = styled.button(({ right }) => [
  tw`absolute top-0 transform -translate-y-1/2`,
  !right && tw`left-0`,
  right && tw`right-0`,
]);

export const ChevronIcon = tw.svg`
  w-12 h-12 text-white fill-current hover:text-gray-500
`;
