import tw, { styled } from 'twin.macro';
import { HeadingSecondary } from './Typography.style';

export const Background = tw.section`
  bg-primary-lighter
`;

export const Section = tw.div`
  flex flex-col xl:flex-row items-center justify-between container mx-auto pt-12 pb-4 sm:pt-16 sm:pb-8 md:pt-16 xl:py-16 px-6
`;

export const Heading = tw(HeadingSecondary)`
  w-full xl:w-services-heading text-center xl:text-left mb-8 xl:m-0
`;

export const List = tw.ul`
  w-full xl:w-services flex flex-wrap
`;

export const Item = tw.li`
  flex-services py-8 px-6
`;

export const Detail = tw.p`
 mt-3
`;

export const SubList = tw.ul`
  mt-3
`;

export const Icon = styled.img(({ roadIcon }) => [
  tw`mb-2 md:mb-4 w-12 h-12`,
  roadIcon && tw`relative -left-1`,
]);
