import React from 'react';
import {
  Container,
  Img,
  Body,
  Heading,
  Infos,
  Info,
  Label,
} from './Card.style';

function Card({
  element,
  img,
  title,
  year,
  city,
  entrusting,
  singleRow = false,
  images,
  onCardClick,
}) {
  const handleClick = () => {
    onCardClick({ images, alt: title });
  };

  return (
    <Container className={element} singleRow onClick={handleClick}>
      <Img src={img} alt={title} />
      <Body>
        <Heading>{title}</Heading>
        <Infos>
          <Info>
            <Label>Év:</Label>
            <strong>{year}</strong>
          </Info>
          <Info>
            <Label>Város:</Label>
            <strong>{city}</strong>
          </Info>
          <Info>
            <Label>Megbízó:</Label>
            <strong>{entrusting}</strong>
          </Info>
        </Infos>
      </Body>
    </Container>
  );
}

export default Card;
