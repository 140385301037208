import React, { useContext } from 'react';
import tw, { GlobalStyles } from 'twin.macro';
import GlobalStyle from '../globalStyle';
import { Header, Footer } from './';
import { Link } from 'gatsby';
import logo from '../assets/images/logo.png';
import megbizhato from '../assets/images/kiemelten_megbizhato.jpg';
import FixedBodyContext from '../context/fixedBody';

const Main = tw.div`
  text-dark tracking-tight text-base lg:text-lg min-h-screen flex flex-col
`;

const Content = tw.div`
  pt-header flex-1
`;

const Layout = ({ children }) => {
  const { isBodyFixed } = useContext(FixedBodyContext);

  return (
    <Main>
      <GlobalStyles />
      <GlobalStyle isBodyFixed={isBodyFixed} />
      <Header />
      <Content>{children}</Content>
      <Footer.Background>
        <Footer.Container>
          <Footer.Logo
            src={logo}
            alt="Truck Tor Kft. logó lábléc"
            height="67"
          />
          <Footer.Nav>
            <Footer.NavList>
              <Footer.NavItem>
                <Link to="/">Főoldal</Link>
              </Footer.NavItem>
              <Footer.NavItem>
                <Link to="/rolunk/">Rólunk</Link>
              </Footer.NavItem>
              <Footer.NavItem>
                <Link to="/referenciak/">Referenciák</Link>
              </Footer.NavItem>
              <Footer.NavItem>
                <Link to="/fejlesztes/">Fejlesztés</Link>
              </Footer.NavItem>
              <Footer.NavItem>
                <Link to="/kapcsolat/">Kapcsolat</Link>
              </Footer.NavItem>
            </Footer.NavList>
          </Footer.Nav>
          <img
            src={megbizhato}
            alt="Kiemelten megbízható vállalkozás 2019 díj"
          />
        </Footer.Container>
      </Footer.Background>
    </Main>
  );
};

export default Layout;
