import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';

export const HeadingSecondary = tw.h2`
  text-center font-bold uppercase text-3xl sm:text-2xl lg:text-4xl mb-8
`;

export const Logo = tw.img`
  h-[150px] sm:mx-auto mb-8 md:fixed top-[5.25rem] left-0
`;

export const LogoNew = tw(Logo)`
  h-szechenyi
`;

export const Paragraph = styled.p(({ narrow, noMargin, mt }) => [
  tw`mb-8 last:mb-0`,
  narrow && tw`mb-4 last:mb-0`,
  noMargin && tw`m-0`,
  mt && tw`mt-4`,
]);

export const HeadingTertiary = tw.h3`
  font-bold mb-2
`;

export const List = tw.ul`
  list-disc list-inside
`;

export const FirstList = tw(List)`
  mb-4
`;

export const PartnersSection = tw.section`
  my-12
`;

export const PartnerLogos = tw.figure`
  flex flex-col md:flex-row items-center justify-evenly
`;

export const PartnerLogo = tw.img`
  h-16 my-2
`;

export const Iframe = tw.iframe`
  mx-auto my-4 max-w-full
`;

export const Nav = tw.nav`
 w-fit mx-auto mt-12
`;

export const NavLink = tw(Link)`
  text-primary-default
`;

export const Container = tw.section`
  md:w-[70vw] md:mr-4 xl:mr-auto mx-auto py-12 sm:py-16 md:py-24 px-6
`