import tw, { styled } from 'twin.macro';

export const Container = styled.li(({ singleRow }) => [
  tw`shadow-lg rounded-md hover:shadow-2xl transition-shadow duration-300 ease-in-out hover:cursor-pointer m-4 flex-services`,
  singleRow && tw`md:max-w-card-md lg:max-w-card-lg`,
]);

export const Img = tw.img`
  rounded-t-md h-half-screen sm:h-references-md md:h-references-sm w-full object-cover
`;

export const Body = tw.div`
  py-1 px-2
`;

export const Heading = tw.h2`
  font-semibold text-base sm:text-lg lg:text-xl mb-3
`;

export const Infos = tw.ul`
  text-sm leading-5 py-2
`;

export const Info = tw.ul`
  mb-3 last:mb-0
`;

export const Label = tw.span`
  uppercase text-gray-500 block
`;
