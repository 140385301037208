exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fejlesztes-ginop-plusz-1-2-3-21-2022-02405-index-js": () => import("./../../../src/pages/fejlesztes/GINOP_PLUSZ-1.2.3-21-2022-02405/index.js" /* webpackChunkName: "component---src-pages-fejlesztes-ginop-plusz-1-2-3-21-2022-02405-index-js" */),
  "component---src-pages-fejlesztes-index-js": () => import("./../../../src/pages/fejlesztes/index.js" /* webpackChunkName: "component---src-pages-fejlesztes-index-js" */),
  "component---src-pages-fejlesztes-vekop-1-2-6-20-2020-00247-index-js": () => import("./../../../src/pages/fejlesztes/VEKOP-1.2.6-20-2020-00247/index.js" /* webpackChunkName: "component---src-pages-fejlesztes-vekop-1-2-6-20-2020-00247-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-referenciak-js": () => import("./../../../src/pages/referenciak.js" /* webpackChunkName: "component---src-pages-referenciak-js" */),
  "component---src-pages-rolunk-js": () => import("./../../../src/pages/rolunk.js" /* webpackChunkName: "component---src-pages-rolunk-js" */)
}

