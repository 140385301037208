import { Typography } from '.';
import tw from 'twin.macro';

export const Heading = tw(Typography.HeadingSecondary)`
  text-center mb-4 md:mb-6 lg:mb-8 xl:mb-10
`;

export const ImgsContainer = tw.div`
  flex flex-wrap
`;

export const Figure = tw.figure`
  w-full sm:w-1/2 py-2 sm:p-2 md:p-4
`;

export const Img = tw.img`
  w-full h-full sm:h-references-sm md:h-references-md lg:h-references-lg xl:h-references-xl shadow-lg rounded-md
`;
