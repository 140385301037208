import React from 'react';
import { Item, Icon } from './ListItem.style';

function ListItem({ children }) {
  return (
    <Item>
      <Icon
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.923 5.999C13.974 6.325 14 6.66 14 7C14 10.59 11.09 13.5 7.5 13.5C3.91 13.5 1 10.59 1 7C1 3.41 3.91 0.5 7.5 0.5C8.582 0.5 9.602 0.764 10.5 1.232"
          stroke="#1F1F1F"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 5.5L7.5 8.5L15.5 0.5"
          stroke="#1F1F1F"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
      {children}
    </Item>
  );
}

export default ListItem;
